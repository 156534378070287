<template>
  <v-container class="form__container" fluid>
    <v-row no-gutters justify="center">
      <v-col cols="12" md="6">
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h6 text-uppercase" />
            </v-col>
          </v-row>
          <template v-if="hasPermission(employeePermissions.PAYMENTS)">
            <v-row>
              <v-col>
                <span class="text-h5">{{
                  $trans("package_not_available_title")
                }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col>
                <v-btn
                  color="blue"
                  dark
                  depressed
                  x-large
                  @click="
                    closeDialog() && $root.$emit('goToPluginsStore', false)
                  "
                >
                  {{ $trans("enable_package") }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col>
                <span class="text-h5">{{
                  $trans("plugin_not_active_employee_info")
                }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col>
                <v-btn
                  color="blue"
                  dark
                  depressed
                  x-large
                  @click="closeDialog()"
                >
                  {{ $trans("close") }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-col>
      <v-col cols="6" class="d-none d-md-flex align-center justify-center">
        <v-img
          alt="Upgrade"
          contain
          class="ma-2"
          :src="require('@/lib/calendesk-js-library/assets/upgrade.png')"
          max-width="400"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  employeePermissions,
  hasPermission,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "PackageNotAvailableDialog",
  mixins: [sharedActions],
  data() {
    return {
      employeePermissions: employeePermissions,
    };
  },
  created() {
    pushEvent("packageNotAvailableDialog");
  },
  methods: {
    hasPermission,
  },
};
</script>

<style lang="scss"></style>
